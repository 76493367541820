import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="message-assistant"
export default class extends Controller {
  static values = {
    chatId: Number,
    messageId: Number,
    responseToId: Number
  }

  static targets = ["content"]

  connect() {
    if (this.contentTarget.innerText) {
      const spinner = document.querySelector(`#chat_${this.chatIdValue}_message_${this.responseToIdValue}`)
      if(spinner) {
        spinner.remove()
      }
    }
  }
}
