import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="typing"
export default class extends Controller {
  static targets = ["dots", "message"]
  static values = {
    interval: { type: Number, default: 500 },
    maxDots: { type: Number, default: 8 }
  }

  connect() {
    this.currentDotCount = 0
    if (this.hasMessageTarget) {
      this.messageTarget.innerText = this.message()
    }
    this.startAnimation()
  }

  disconnect() {
    this.stopAnimation()
  }

  message() {
    const messages = [
      "Dotting my I's and crossing my T's",
      "Thinking",
      "Doing some research"
    ]
    return messages[Math.floor(Math.random() * messages.length)];
  }

  startAnimation() {
    this.animationId = setInterval(() => {
      this.currentDotCount = ((this.currentDotCount % this.maxDotsValue) + 1)
      this.dotsTarget.textContent = ".".repeat(this.currentDotCount)
    }, this.intervalValue)
  }

  stopAnimation() {
    if (this.animationId) {
      clearInterval(this.animationId)
    }
  }
}
