import { Controller } from "@hotwired/stimulus"
import { useTransition } from 'stimulus-use'

export default class extends Controller {
  static targets = ["textarea", "submitButton", "contextSelector", "form"]

  connect() {
    if (this.hasContextSelectorTarget) {
      useTransition(this, {
        element: this.contextSelectorTarget,
        enterActive: 'transition ease-out duration-300',
        enterFrom: 'transform opacity-0 scale-95',
        enterTo: 'transform opacity-100 scale-100',
        leaveActive: 'transition ease-in duration-300',
        leaveFrom: 'transform opacity-100 scale-100',
        leaveTo: 'transform opacity-0 scale-95',
      })
    }
    this.contentChanged()

  }

  contentChanged() {
    this.submitButtonTarget.disabled = !this.hasEnoughContent()
  }

  hasEnoughContent() {
    return this.textareaTarget.value.trim().length > 5
  }

  submitOnEnter(event) {
    if (this.hasEnoughContent() && event.key === 'Enter' && event.shiftKey) {
      event.preventDefault()
      this.submit()
    }
  }

  submit() {
    if (this.hasFormTarget) {
      this.formTarget.requestSubmit()
    }
  }

  toggleContextSelector() {
    if (this.hasContextSelectorTarget) {
      if (this.contextSelectorTarget.classList.contains('hidden')) {
        this.enter()
      } else {
        this.leave()
      }
    }
  }

  enter() {
    if (this.hasContextSelectorTarget) {
      this.contextSelectorTarget.classList.remove('hidden')
    }
  }

  leave() {
    if (this.hasContextSelectorTarget) {
      this.contextSelectorTarget.classList.add('hidden')
    }
  }
}
